<!-- 作业详情 单题切换  -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
.page{
    position: relative;
	width: 100%;
    height: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
}
*{
	box-sizing: border-box;
}
.content{
	padding: 32px 0px 0;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
	.encourageImg{
		width: 576px;
		height: 151px;
		display: block;
	}
	.emphasis{
		background: #FDFDFD;
        border-radius: 14px;
        border: 1px solid #BDCADC;
        padding: 18px;
        margin-bottom: 24px;
		.h2{
            height: 30px;
            font-size: 16px;
            font-weight: bold;
            color: #222222;
            line-height: 30px;
            margin-bottom: 10px;
			.iconfont{
                font-weight: initial;
                color: #FFFFFF;
                width: 26px;
                height: 26px;
                font-size: 14px;
                line-height: 26px;
                text-align: center;
                background: linear-gradient(146deg, #00D0FA 0%, #00A1FA 100%);
                border-radius: 50%;
                margin-right: 10px;
			}
		}
		.h4{
            font-size: 16px;
            font-weight: 400;
            color: #1DBB62;
            line-height: 26px;
            margin-bottom: 6px;
		}
		.p{
			font-size: 16px;
			font-weight: 400;
			color: #444444;
			line-height: 26px;
		}
	}
}
// 小题列表
::v-deep{
	.el-dialog__body{
		width: calc(100% - 40px);
	}
}
.topicList{
	width: 100%;
	height: 600px;
	max-height: 80vh;
	position: relative;
	padding: 20px;
	overflow: auto;
	.questions{
		width: 100%;
		background-color: #FFFFFF;
		margin-bottom: 16px;
		padding: 20px;
		display: inline-block;
		overflow-x: hidden;
		border-radius: 14px;
		box-shadow: 0 0 16px #dddddd;
		.type{
			font-size: 18px;
			font-weight: bold;
			color: #222222;
			line-height: 20px;
			.statistics{
				font-weight: initial;
				font-size: 14px;
			}
		}
		.ul{
			width: 102%;
			display: inline-block;
			.item{
				float: left;
				margin-top: 16px;
				width: 40px;
				height: 40px;
				background: #E2E2E2;
				border-radius: 50%;
				margin-right: 20px;
				text-align: center;
				line-height: 40px;
				color: #FFFFFF;
				font-size: 18px;
				position: relative;
				&:hover{
					opacity: 0.8;
					cursor: pointer;
				}
				&.opt{
					background-color: $comBlue;
				}
			}
		}
	}
}
.showAnswer{
	position: absolute;
    right: 30px;
    top: 15px;
    width: 50px;
    font-size: 22px;
	&:hover{
		opacity: 0.8;
		cursor: pointer;
	}
}

.solutionDrawImg{
	display: flex;
	padding:16px;
	border-radius: 14px;
	margin-bottom: 16px;
	.drawImg{
		margin: 0;
	}
	.iconfont{
		display: none;
	}
	.icona-dui{
		margin-right: 12px;
		font-size: 34px;
		font-weight: initial;
		color: $comGreen;
	}
	.icona-cuo{
		margin-right: 24px;
		font-size: 22px;
		font-weight: initial;
		color: $comRed;
	}
	&.correct{
		background: rgba(29,187,98,0.1);
		.icona-dui{
			display: inline-block;
		}
	}	
	&.mistake{
		background: rgba(255,77,79,0.1);
		.icona-cuo{
			display: inline-block;
		}
	}
}
</style>

<template>
    <div class="page">
		<div class="content">
			<div class="type flex_bet_align">
				<div></div>
				<el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
			</div>
			<div class="questions" v-if="homeworkItem&&homeworkItem.syque_typ_name">
				<div class="type flex_align">
					{{ papIndex + 1 | changeChinesNum }}、{{homework_list[papIndex].stexa_pap_top_title}} 
					<div class="fw-l fs-28 ml-16" v-if="homework_list[papIndex]">
						(共 {{homework_list[papIndex].question.length}}道 共 {{homework_list[papIndex].stexa_pap_top_sumscore}} 分)
					</div>
				</div>
				<div class="topic" v-if="homeworkItem.syque_typ_id!=14">
					<div class="flex"><span class="mold">{{homeworkItem.syque_typ_name}} </span><div class="val">  <em class="richinline" v-html="homeworkItem.stexa_que_title"></em> <span>({{homeworkItem.stexa_que_score}}分)</span></div></div> 
					<img class="selectimg" v-if="homeworkItem.stexa_que_title_image" :src="homeworkItem.stexa_que_title_image" alt="">
					<!-- <el-image v-if="homeworkItem.stexa_que_title_image" style="width: 100px; height: 100px" :src="homeworkItem.stexa_que_title_image" :preview-src-list="[homeworkItem.stexa_que_title_image]"></el-image> -->
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
				<!-- 单选和判断 -->
				
				<div v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2||homeworkItem.syque_typ_id==5">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index">
						<!-- 判断单题的答案下标位置是否等于当前题目的选项值 getopt是否选中  getopt选中的并且已经提交的?(里面其中结果为正确?correct:mistake)  -->
						<div class="li" :class="[getopt(item.stexa_que_ite_code,homeworkItem.stexa_que_stuanswer)?'opt':'',getopt(item.stexa_que_ite_code,homeworkItem.stexa_que_stuanswer)&&homeworkItem.stexa_que_stu_isanswer==1?(item.stexa_que_ite_isanswer==10?'correct':'mistake'):'']">
							<div v-if="item.stexa_que_ite_title">
								<div class="flex">
									<div class="iconfont icona-dui">&#xe6db;</div>
                            		<div class="iconfont icona-cuo">&#xe6dc;</div>
									<div class="number">{{item.stexa_que_ite_code}}.</div>
									<div class="val">
										<div class="richinline" v-html="item.stexa_que_ite_title"></div>
									</div>
								</div>
								<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
								<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
							</div>
							<div class="flex" v-else>
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
								<div class="number">{{item.stexa_que_ite_code}}.</div>
								<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
								<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;margin-top:0;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
							</div>
						</div>
					</div>
				</div>
				<!-- 单项填空 -->
				<div v-if="homeworkItem.syque_typ_id==3">
					<div class="multi" :class="homeworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.stexa_que_stuanswer[0]">
						<div class="input">
							<div class="iconfont icona-dui" :style="index>0?'opacity: 0;':''">&#xe6db;</div>
							<div class="iconfont icona-cuo" :style="index>0?'opacity: 0;':''">&#xe6dc;</div>
							<span class="richblock" v-if="homeworkItem.stexa_que_stuanswer[0]" v-html="homeworkItem.stexa_que_stuanswer[0]"></span>
						</div>
					</div>
					<div class="solutionDrawImg" :class="homeworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.stexa_que_stuanswer_image">
						<div class="iconfont icona-dui">&#xe6db;</div>
						<div class="iconfont icona-cuo">&#xe6dc;</div>
						<img class="selectimg" v-if="homeworkItem.stexa_que_stuanswer_image" :src="homeworkItem.stexa_que_stuanswer_image" alt="">
						<!-- <el-image v-if="homeworkItem.stexa_que_stuanswer_image" style="width: 100px; height: 100px;" :src="homeworkItem.stexa_que_stuanswer_image" :preview-src-list="[homeworkItem.stexa_que_stuanswer_image]"></el-image> -->
					</div>
				</div>
				<!-- 多项填空 -->
				<div v-if="homeworkItem.syque_typ_id==4">
					<div class="caption c-8">请依次将填空题答案输入到下框中</div>
					<div v-if="isshowitem(homeworkItem.stexa_que_stuanswer)">
						<div class="multi" :class="homeworkItem.stexa_que_isstustatus==10?'correct':'mistake'">
							<div v-for="(item,index) in homeworkItem.question_item" :key="index">
								<div class="input">
									<div class="iconfont icona-dui" :style="index>0?'opacity: 0;':''">&#xe6db;</div>
									<div class="iconfont icona-cuo" :style="index>0?'opacity: 0;':''">&#xe6dc;</div>
									<span class="richblock" v-if="homeworkItem.stexa_que_stuanswer[index]" v-html="homeworkItem.stexa_que_stuanswer[index]"></span> 
								</div>
							</div>
						</div>
					</div>
					<div class="solutionDrawImg" :class="homeworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.stexa_que_stuanswer_image">
						<div class="iconfont icona-dui">&#xe6db;</div>
						<div class="iconfont icona-cuo">&#xe6dc;</div>
						<img class="selectimg" v-if="homeworkItem.stexa_que_stuanswer_image" :src="homeworkItem.stexa_que_stuanswer_image" alt="">
						<!-- <el-image v-if="homeworkItem.stexa_que_stuanswer_image" style="width: 100px; height: 100px;" :src="homeworkItem.stexa_que_stuanswer_image" :preview-src-list="[homeworkItem.stexa_que_stuanswer_image]"></el-image> -->
					</div>
				</div>
				<!-- 8问答 -->
				<div v-if="homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8">
					<div class="multi" :class="homeworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.stexa_que_stuanswer[0]">
						<div class="textarea flex">
							<div class="iconfont icona-dui">&#xe6db;</div>
							<div class="iconfont icona-cuo">&#xe6dc;</div>
							<span class="richinline" v-if="homeworkItem.stexa_que_stuanswer[0]" v-html="homeworkItem.stexa_que_stuanswer[0]"></span>
						</div>
					</div>
					<div class="solutionDrawImg" :class="homeworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="homeworkItem.stexa_que_stuanswer_image">
						<div class="iconfont icona-dui">&#xe6db;</div>
						<div class="iconfont icona-cuo">&#xe6dc;</div>
						<img class="selectimg" v-if="homeworkItem.stexa_que_stuanswer_image" :src="homeworkItem.stexa_que_stuanswer_image" alt="">
						<!-- <el-image v-if="homeworkItem.stexa_que_stuanswer_image" style="width: 100px; height: 100px;" :src="homeworkItem.stexa_que_stuanswer_image" :preview-src-list="[homeworkItem.stexa_que_stuanswer_image]"></el-image> -->
					</div>
				</div>
				<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 组合题 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
				<div v-if="homeworkItem.syque_typ_id==14">
					<div class="topic">
						<div class="val">
							<div class="richinline" v-html="homeworkItem.stexa_que_title"></div>
						</div>
						<img class="selectimg" v-if="homeworkItem.stexa_que_title_image" :src="homeworkItem.stexa_que_title_image" alt="">
						<!-- <el-image v-if="homeworkItem.stexa_que_title_image" style="width: 100px; height: 100px;" :src="homeworkItem.stexa_que_title_image" :preview-src-list="[homeworkItem.stexa_que_title_image]"></el-image> -->
					</div>
					<div class="p-0-32">
						<div class="topic">
							<div class="val"><em class="richinline" v-html="groupworkItem.stexa_que_title"></em>
								<span class="fs-28 fw-l">
								（{{groupworkItem.syque_typ_name}}）
								</span>
							</div>
							<img class="selectimg" v-if="groupworkItem.stexa_que_title_image" :src="groupworkItem.stexa_que_title_image" alt="">
							<!-- <el-image v-if="groupworkItem.stexa_que_title_image" style="width: 100px; height: 100px;" :src="groupworkItem.stexa_que_title_image" :preview-src-list="[groupworkItem.stexa_que_title_image]"></el-image> -->
						</div>
						
						<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
						<!-- 单选和判断 -->
						<div v-if="groupworkItem.syque_typ_id==1||groupworkItem.syque_typ_id==2||groupworkItem.syque_typ_id==5">
							<div v-for="(item,index) in groupworkItem.question_child_item" :key="index">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="[getopt(item.stexa_que_ite_code,groupworkItem.stexa_que_stuanswer)?'opt':'',getopt(item.stexa_que_ite_code,groupworkItem.stexa_que_stuanswer)&&groupworkItem.stexa_que_stu_isanswer>0?(item.stexa_que_ite_isanswer==10?'correct':'mistake'):'']">
									<div v-if="item.stexa_que_ite_title">
										<div class="flex">
											<div class="iconfont icona-dui">&#xe6db;</div>
                            				<div class="iconfont icona-cuo">&#xe6dc;</div>
											<div class="number">{{item.stexa_que_ite_code}}.</div>
											<div class="val">
												<div class="richinline" v-html="item.stexa_que_ite_title"></div>
											</div>
										</div>
										<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
										<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
									</div>
									<div class="flex" v-else>
										<div class="iconfont icona-dui">&#xe6db;</div>
                            			<div class="iconfont icona-cuo">&#xe6dc;</div>
										<div class="number">{{item.stexa_que_ite_code}}.</div>
										<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
										<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 100px; height: 100px;margin-top:0;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
						</div>
						<!-- 单项填空 -->
						<div v-if="groupworkItem.syque_typ_id==3">
							<div class="multi" :class="groupworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="groupworkItem.stexa_que_stuanswer[0]">
								<div class="input">
									<div class="iconfont icona-dui">&#xe6db;</div>
                            		<div class="iconfont icona-cuo">&#xe6dc;</div>
									<span class="richblock" v-if="groupworkItem.stexa_que_stuanswer[0]" v-html="groupworkItem.stexa_que_stuanswer[0]"></span>
								</div>
							</div>
							<div class="solutionDrawImg" :class="groupworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="groupworkItem.stexa_que_stuanswer_image">
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
								<img class="selectimg" v-if="groupworkItem.stexa_que_stuanswer_image" :src="groupworkItem.stexa_que_stuanswer_image" alt="">
								<!-- <el-image v-if="groupworkItem.stexa_que_stuanswer_image" style="width: 100px; height: 100px;" :src="groupworkItem.stexa_que_stuanswer_image" :preview-src-list="[groupworkItem.stexa_que_stuanswer_image]"></el-image> -->
							</div>
						</div>
						<!-- 多项填空 -->
						<div v-if="groupworkItem.syque_typ_id==4">
							<div class="caption c-8">请依次将填空题答案输入到下框中</div>
							<div v-if="isshowitem(groupworkItem.stexa_que_stuanswer)">
								<div class="multi" :class="groupworkItem.stexa_que_isstustatus==10?'correct':'mistake'">
									<div v-for="(item,index) in groupworkItem.question_child_item" :key="index">
										<div class="input">
											<div class="iconfont icona-dui" :style="index>0?'opacity: 0;':''">&#xe6db;</div>
                            				<div class="iconfont icona-cuo" :style="index>0?'opacity: 0;':''">&#xe6dc;</div>
											<span class="richblock" v-if="groupworkItem.stexa_que_stuanswer[index]" v-html="groupworkItem.stexa_que_stuanswer[index]"></span>
										</div>
									</div>
								</div>
							</div>
							<div class="solutionDrawImg" :class="groupworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="groupworkItem.stexa_que_stuanswer_image">
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
								<img class="selectimg" v-if="groupworkItem.stexa_que_stuanswer_image" :src="groupworkItem.stexa_que_stuanswer_image" alt="">
								<!-- <el-image v-if="groupworkItem.stexa_que_stuanswer_image" style="width: 100px; height: 100px;" :src="groupworkItem.stexa_que_stuanswer_image" :preview-src-list="[groupworkItem.stexa_que_stuanswer_image]"></el-image> -->
							</div>
						</div>
						<!--7计算 8问答 -->
						<div v-if="groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<div class="multi" :class="groupworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="groupworkItem.stexa_que_stuanswer[0]">
								<div class="textarea flex">
									<div class="iconfont icona-dui">&#xe6db;</div>
                            		<div class="iconfont icona-cuo">&#xe6dc;</div>
									<span class="richinline" v-if="groupworkItem.stexa_que_stuanswer[0]" v-html="groupworkItem.stexa_que_stuanswer[0]"></span>
								</div>
							</div>
							<div class="solutionDrawImg" :class="groupworkItem.stexa_que_isstustatus==10?'correct':'mistake'" v-if="groupworkItem.stexa_que_stuanswer_image">
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
								<img class="selectimg" v-if="groupworkItem.stexa_que_stuanswer_image" :src="groupworkItem.stexa_que_stuanswer_image" alt="">
								<!-- <el-image v-if="groupworkItem.stexa_que_stuanswer_image" style="width: 100px; height: 100px;" :src="groupworkItem.stexa_que_stuanswer_image" :preview-src-list="[groupworkItem.stexa_que_stuanswer_image]"></el-image> -->
							</div>
						</div>
						<div class="emphasis">
							<div class="h2 flex_align">
								<div class="iconfont">&#xe6d9;</div>
								答案解析
							</div>
							<div class="h4" v-if="groupworkItem.system_answer">正确答案：<span class="richinline" v-html="groupworkItem.system_answer"></span></div>
							<div class="p richblock" v-if="homeworkItem.stexa_que_analysis" v-html="homeworkItem.stexa_que_analysis"></div>
							<div class="noexplain" v-else>暂无数据</div>
						</div>
						<div class="emphasis">
							<div class="h2 flex_align">
								<div class="iconfont">&#xe6da;</div>
								知识点
							</div>
							<div class="p" v-if="homeworkItem.tetea_kno_content">{{homeworkItem.tetea_kno_content}}</div>
							<div class="noexplain" v-else>暂无数据</div>
						</div>
						<div class="flex_bet_align groupOperate">
							<div class="combtn3 btn" v-if="grouTopicIndex>0" @click="changeGrouPrior">
								<div class="iconfont">&#xe6d2;</div>
								&nbsp;&nbsp;上一题
							</div>
							<div class="combtn4 btn" v-else>
								<div class="iconfont">&#xe6d2;</div> 
								&nbsp;&nbsp;上一题
							</div>
							<div class="combtn3 btn" v-if="(grouTopicIndex+1)<homeworkItem.question_item.length" @click="changeGrouNext">
								下一题&nbsp;&nbsp;
								<div class="iconfont">&#xe6cf;</div>
							</div>
							<div class="combtn4 btn" v-else>
								下一题 &nbsp;&nbsp;
								<div class="iconfont">&#xe6cf;</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="homeworkItem&&homeworkItem.stexa_que_ismakeup!=10">
				<div class="emphasis">
					<div class="h2 flex_align">
						<div class="iconfont">&#xe6d9;</div>
						答案解析
					</div>
					<div class="h4" v-if="homeworkItem.system_answer">正确答案：<span class="richinline" v-html="homeworkItem.system_answer"></span></div>
					<div class="p richblock" v-if="homeworkItem.stexa_que_analysis" v-html="homeworkItem.stexa_que_analysis"></div>
					<div class="noexplain" v-else>暂无数据</div>
				</div>
				<div class="emphasis">
					<div class="h2 flex_align">
						<div class="iconfont">&#xe6da;</div>
						知识点
					</div>
					<div class="p" v-if="homeworkItem.tetea_kno_content">{{homeworkItem.tetea_kno_content}}</div>
					<div class="noexplain" v-else>暂无数据</div>
				</div>
			</div>
		</div>
		<div class="flotageBtn bg-c-f">
			<div class="combtn3" v-if="papIndex>0||topicIndex>0" @click="changePrior">上一题</div>
			<div class="combtn4" v-else>上一题</div>
			<div class="combtn3" @click="changeNext" v-if="(homework_list.length>papIndex+1)||(homework_list[papIndex]&&homework_list[papIndex].question.length>topicIndex+1)">下一题</div>
			<div class="combtn4" @click="changeNext" v-else>查看全部题</div>
			<div class="combtn3 showAnswer" @click="showAnswer=true" v-if="(homework_list.length>papIndex+1)||(homework_list[papIndex]&&homework_list[papIndex].question.length>topicIndex+1)">
				<div class="iconfont">&#xe681;</div>
			</div>
		</div>
		<!-- 显示答题进度 -->
		<el-dialog title="试卷目录" :visible.sync="showAnswer" width="50%">
			<div class="topicList">
				<div v-for="(item,index) in homework_list" :key="index">
					<div class="questions">
						<div class="type flex_content_wrap">
							<div>{{ index + 1 | changeChinesNum }}、{{item.stexa_pap_top_title}} </div> 
							<div class="statistics c-8">（共{{item.question.length}}道，共{{item.stexa_pap_top_sumscore}}分）</div>
						</div>
						<div class="ul">
							<div v-for="(item2,index2) in item.question" :key="index2">
								<div class="item" :class="item2.stexa_que_stu_isanswer?'opt':''" @click="clickQuestion(index,index2)">{{index2+1}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </el-dialog>
	</div>	
</template>
<script>
import { stuexamination_start_answer } from "@api/home"
export default {
    name: 'examinationDetailsItem',
    data() {
        return {
			//试卷id
			stexa_id:0,
			//所有试题
			homework_list:[
				{question:""}
			],
			//单题内容
			homeworkItem:{},
			//组合题单题内容
			groupworkItem:{},
			//题型下标
			papIndex:0,
			//题目下标
			topicIndex:0,
			//组合题小题下标
			grouTopicIndex:0,
			//总题数量
			allPapLength:0,
			//显示所有试题
			showAnswer:false
        }
    },
    created() {
        this.papIndex=parseInt(this.$route.params.index1)
        this.topicIndex=parseInt(this.$route.params.index2)
        this.stexa_id=this.$route.params.id
        this.getData();
    },
    methods: {
        async getData() {
            let { data } = await stuexamination_start_answer({stexa_id:this.stexa_id});
			this.homework_list=data
			//渲染单题
			this.homeworkItem=data[this.papIndex].question[this.topicIndex]
			//判断第一类是否为组合提
			if(data[this.papIndex].question[this.topicIndex].syque_typ_id==14){
				this.groupworkItem=data[this.papIndex].question[this.topicIndex].question_item[0]
			}
            this.$forceUpdate();
        },
       //页面大题切换上一题
		changePrior(){
			//判断当前大类里面是否为还有上一题  true有上一题
			if(this.topicIndex>0){
				//回到上一小题
				this.topicIndex=this.topicIndex-1
				this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
				if(this.homework_list[this.papIndex].question[0].syque_typ_id==14){
					this.grouTopicIndex=0
					this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
				}
			}else{
				// 判断是否还有上一大类 true还有上一大类
				if(this.papIndex>0){
					//题类型+1
					this.papIndex=this.papIndex-1
					//大题下标跳转到上一个类型的最后一个
					this.topicIndex=this.homework_list[this.papIndex].question.length-1
					//已经是第一个题了，判断上一个大类题型  不是组合提的情况下
					if(this.homework_list[this.papIndex].question[this.topicIndex].syque_typ_id!=14){
						//替换值
						this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
					}else{
						//小题下标重置
						this.grouTopicIndex=0
						//替换值
						this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
						this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
					}
				}else{
					//组合提填充
					this.$message({
						message: '没有上一题了',
						type: 'warning'
					});
				}
			}
		},
		//页面大题切换下一题
		changeNext(){
			// 判断大类里面的大题后面是否还有小题
			if(this.homework_list[this.papIndex].question.length>this.topicIndex+1){
				//还有下一题
				this.topicIndex=this.topicIndex+1
				this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
				if(this.homework_list[this.papIndex].question[0].syque_typ_id==14){
					this.grouTopicIndex=0
					this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
				}
			}else{
				// 切换下一个题型
				//判断下一个类型是否也是最后一个 true还有下一个类型
				if(this.homework_list.length>this.papIndex+1){
					//题类型+1
					this.papIndex=this.papIndex+1
					//大题下标重置
					this.topicIndex=0
					//判断是否是组合提
					if(this.homework_list[this.papIndex].question[0].syque_typ_id!=14){
						//替换值
						this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
					}else{
						//组合提填充
						//小题下标重置
						this.grouTopicIndex=0
						this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
						this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
					}
				}else{
					//最后一题
					this.showAnswer=true
				}
			}
		},
		
		//页面小题切换上一题
		changeGrouPrior(){
			//小题是否是第一个  true还有上一小题
			if(this.grouTopicIndex>0){
				//回到上一小题
				// 切换小题
				//还有上一题
				this.grouTopicIndex=this.grouTopicIndex-1
				this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
			}else{
				// 判断大题是否是第一个 true还有上一个大题
				if(this.topicIndex>0){
					//回到上一小题
					//大题累加
					this.topicIndex=this.topicIndex-1
					//小题下标重置
					this.grouTopicIndex=0
					//替换值
					this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
					this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
				}else{
					// 该类型已经是第一个第一题
					//判断是否是第一个类型 true 还有上一个类型
					if(this.papIndex>0){
						//题类型+1
						this.papIndex=this.papIndex-1
						//大题下标重置
						this.topicIndex=this.homework_list[this.papIndex].question.length-1
						//小题下标重置
						this.grouTopicIndex=0
						//判断是否是组合提 true不是组合提
						if(this.homework_list[this.papIndex].question[this.topicIndex].syque_typ_id!=14){
							//替换值
							this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
						}else{
							//组合题填充
							//替换值
							this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
							this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
						}
					}else{
						this.$message({
							message: '没有上一题了',
							type: 'warning'
						});
					}
				}
			}
		},
		//页面小题切换下一题
		changeGrouNext(){
			// 判断组合提里面的大题里面的小题是否还有下一题 true还有下一小题
			if(this.homework_list[this.papIndex].question[this.topicIndex].question_item.length>this.grouTopicIndex+1){
				// 切换小题
				//还有下一题
				this.grouTopicIndex=this.grouTopicIndex+1
				this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
			}else{
				// 切换大题
				//判断类型下面的大题不是最后一题 true还有下大题
				if(this.homework_list[this.papIndex].question.length>this.topicIndex+1){
					//大题累加
					this.topicIndex=this.topicIndex+1
					//小题下标重置
					this.grouTopicIndex=0
					//替换值
					this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
					this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
				}else{
					//是否最后一个类型 true还有下一类
					if(this.homework_list.length>this.papIndex+1){
						//题类型+1
						this.papIndex=this.papIndex+1
						//大题下标重置
						this.topicIndex=0
						//小题下标重置
						this.grouTopicIndex=0
						//判断是否是组合提 true不是组合提
						if(this.homework_list[this.papIndex].question[0].syque_typ_id!=14){
							//还有下一题 
							//替换值
							this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
						}else{
							//组合题填充
							//替换值
							this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
							this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
						}
					}else{
						this.$message({
							message: '已经是该类型最后一题',
							type: 'warning'
						});
					}
				}
			}
		},
		//试题列表 点击试题类表里面某个值
		clickQuestion(index,index2){
			this.showAnswer=false
			this.topicIndex=index2
			this.papIndex=index
			this.grouTopicIndex=0
			this.homeworkItem=this.homework_list[this.papIndex].question[this.topicIndex]
			//多选题
			if(this.homework_list[this.papIndex].question[this.topicIndex].syque_typ_id==14){
				this.groupworkItem=this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]
			}
		},
		//判断试题是否选中 answer答案  list
		getopt(answer,list){
			if(answer&&list&&list.length>0){
				return list.includes(answer)
			}else{
				return false
			}
		},
		//判断列表里面是否有填写
		isshowitem(list){
			let isshows = list.some(item=>{
				return item.length>0
			})
			return isshows
		}
    },
}
</script>